//hooks and libraries
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//pages
import {Pokedex} from './pages/Pokedex'
import { RegisterPokemons } from './pages/RegisterPokemons';

export const App = () =>  {
  return (
    <div className='appContainer'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Pokedex/>}/>
          <Route path='/registerpokemon' element={<RegisterPokemons/>}/>
        </Routes>
      </BrowserRouter>
    </div>
    
  );
}
