//CSS
import styles from './StatesBars.module.css'

export const StatesBars = ({hp, atk, def, speed}) => {
    function renderStatus(activedBars){
        var render = []
        for (let i = 0; i < 10; i++) {
            if(i<activedBars){
                render[i] = 'actived'
            }else{
                render[i] = 'disabled'
            } 
        }
        return render
    }

    var hpBar = renderStatus(hp)
    var atkBar = renderStatus(atk)
    var defBar = renderStatus(def)
    var speedBar = renderStatus(speed)


    return (
        <div className={styles.statsBox}>
            <div className={styles.barBox}>
                <span className={styles.statsTitle}>HP</span>
                {
                    hpBar.map((item, index) => {
                        if (item === 'actived'){
                            return <div key={index} className={`${styles.actived} ${styles.bar}`}></div>
                        }else{
                            return <div key={index} className={`${styles.disabled} ${styles.bar}`}></div>
                        }   
                        
                    })
                }
            </div>
            <div className={styles.barBox}>
                <span className={styles.statsTitle}>Atk</span>
                {
                    atkBar.map((item, index) => {
                        if (item === 'actived'){
                            return <div key={index} className={`${styles.actived} ${styles.bar}`}></div>
                        }else{
                            return <div key={index} className={`${styles.disabled} ${styles.bar}`}></div>
                        }   
                        
                    })
                }
            </div>
            <div className={styles.barBox}>
                <span className={styles.statsTitle}>Def</span>
                {
                    defBar.map((item, index) => {
                        if (item === 'actived'){
                            return <div key={index} className={`${styles.actived} ${styles.bar}`}></div>
                        }else{
                            return <div key={index} className={`${styles.disabled} ${styles.bar}`}></div>
                        }   
                        
                    })
                }
            </div>
            <div className={styles.barBox}>
                <span className={styles.statsTitle}>Speed</span>
                {
                    speedBar.map((item, index) => {
                        if (item === 'actived'){
                            return <div key={index} className={`${styles.actived} ${styles.bar}`}></div>
                        }else{
                            return <div key={index} className={`${styles.disabled} ${styles.bar}`}></div>
                        }   
                        
                    })
                }
            </div>
        </div>
    )
}
