import React, { useState } from 'react'
import { db } from '../firebase/FirebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

export const RegisterPokemons = () => {
    const[name, setName] = useState('');
    const[id, setId] = useState(0);
    const[type, setType] = useState('');
    const[weaknesses, setWeaknesses] = useState('');
    const[HP, setHP] = useState(0);
    const[atk, setAtk] = useState(0);
    const[def, setDef] = useState(0);
    const[speed, setSpeed] = useState(0);
    const[category, setCategory] = useState('');
    const[abilities, setAbilities] = useState('');


    async function registerPokemon(e){
        e.preventDefault()
        let typeArray = type.split(',').map((item) => item.trim());
        let weaknessesArray = weaknesses.split(',').map((item) => item.trim());
        let categoryArray = category.split(',').map((item) => item.trim());
        let abilitiesArray = abilities.split(',').map((item) => item.trim());
        let file = e.target[10].files[0];

        

        const storage = getStorage()
        const storageRef = ref(storage, name )

        await uploadBytesResumable(storageRef, file).then(() => {
            getDownloadURL(storageRef).then(async (downloadURL) =>{
                var document = {
                    name,
                    id,
                    typeArray,
                    weaknessesArray,
                    HP,
                    atk,
                    def,
                    speed,
                    categoryArray,
                    abilitiesArray,
                    pokemonURL: downloadURL
                }

                await setDoc(doc(db, 'Pokemons', id), document )
            })
        })

        console.log(id.valueOf)
        setName('');
        setId('');
        //setType('');
        //setWeaknesses('');
       // setHP(0);
        //setAtk(0);
       // setDef(0);
        //setSpeed(0);
        //setCategory('');
        //setAbilities('');

        
    }

    return (
        <div>
            <form onSubmit={registerPokemon}>
                <label htmlFor="namePokemon">Name:</label>
                <input type="text" name='namePokemon' id='namePokemon' value={name} onChange={e => setName(e.target.value)}/> <br/>
                <label htmlFor="idPokemon">Id:</label>
                <input type="number" name='idPokemon' id='idPokemon' value={id} onChange={e => setId(e.target.value)}/><br/>
                <label htmlFor="TypePokemon">Type:</label>
                <input type="text" name='TypePokemon' id='TypePokemon' value={type} onChange={e => setType(e.target.value)}/><br/>
                <label htmlFor="WeaknessesPokemon">Weaknesses:</label>
                <input type="text" name='WeaknessesPokemon' id='WeaknessesPokemon' value={weaknesses} onChange={e => setWeaknesses(e.target.value)}/><br/>
                <label htmlFor="HPPokemon">HP:</label>
                <input type="number" name='HPPokemon' id='HPPokemon' value={HP} onChange={e => setHP(e.target.value)}/><br/>
                <label htmlFor="AtkPokemon">Atk:</label>
                <input type="number" name='AtkPokemon' id='AtkPokemon' value={atk} onChange={e => setAtk(e.target.value)}/><br/>
                <label htmlFor="DefPokemon">Def:</label>
                <input type="number" name='DefPokemon' id='DefPokemon' value={def} onChange={e => setDef(e.target.value)}/><br/>
                <label htmlFor="SpeedPokemon">Speed:</label>
                <input type="number" name='SpeedPokemon' id='SpeedPokemon' value={speed} onChange={e => setSpeed(e.target.value)}/><br/>
                <label htmlFor="CategoryPokemon">Category:</label>
                <input type="text" name='CategoryPokemon' id='CategoryPokemon' value={category} onChange={e => setCategory(e.target.value)}/> <br/>
                <label htmlFor="AbilitiesPokemon">Abilities:</label>
                <input type="text" name='AbilitiesPokemon' id='AbilitiesPokemon' value={abilities} onChange={e => setAbilities(e.target.value)}/> <br/>
                <label htmlFor="ImgPokemon">escolha a imagem do pokemon</label>
                <input type="file" name='ImgPokemon' id='ImgPokemon' accept='image/png'/> <br/>
                <button>Registrar Pokemon</button>
                <button onClick={(e) => console.log(e.target[0].value)}>log</button>
            </form>
        </div>
  )
}
