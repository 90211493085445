//CSS
import styles from './Pokedex.module.css'
import './TypeStyles.css'

//Images
import headerImg from '../images/header.png'
import displayImg from '../images/displayImg.png'
import left from '../images/left.png'
import right from '../images/right.png'
import leftPress from '../images/leftPress.png'
import rightPress from '../images/rightPress.png'

//Hooks
import { useState } from 'react'

//Components
import { StatesBars } from '../components/StatesBars'

//FirebaseServices
import { getDoc, doc } from 'firebase/firestore'
import { db } from '../firebase/FirebaseConfig'


export const Pokedex = () => {
  const [pokemon, setPokemon] = useState({
    name: 'Bulbassaur',
    id: '1',
    type: [
      'grass',
      'poison'
    ],
    weaknesses:[
      'fire',
      'psychic',
      'flying',
      'ice'
    ],
    hp: 3,
    atk: 3,
    def: 3,
    speed: 3,
    category:[
      'seed'
    ],
    abilities:[
      'overgrow'
    ],
    pokemonURL: "https://firebasestorage.googleapis.com/v0/b/pokedex-f0e10.appspot.com/o/Bulbassaur?alt=media&token=0787c910-5181-4270-bc06-f7530d91ecb5"
  })


  const [buttonLeft, setButtonLeft] = useState(left)
  const [buttonRight, setButtonRight] = useState(right)  
  

  async function getPokemon (id){

    const docSnap = await getDoc(doc(db, 'Pokemons', `${id}`))
    const pokemon = {
      name: docSnap.data().name,
      id: docSnap.data().id,
      type: docSnap.data().typeArray,
      weaknesses: docSnap.data().weaknessesArray,
      hp: docSnap.data().HP,
      atk: docSnap.data().atk,
      def: docSnap.data().def,
      speed: docSnap.data().speed,  
      abilities: docSnap.data().abilitiesArray,
      category: docSnap.data().categoryArray,
      pokemonURL: docSnap.data().pokemonURL
      }
    setPokemon(pokemon)
  }

  function pressLeft(){
    setButtonLeft(leftPress)
    setTimeout(() => {setButtonLeft(left)}, 100)
    if(pokemon.id === '1'){
      getPokemon(37)
      return
    }
    getPokemon((parseInt(pokemon.id)  - 1))

  }
  function pressRight(){
    setButtonRight(rightPress)
    setTimeout(() => {setButtonRight(right)}, 100)
    if(pokemon.id === '37'){
      getPokemon(1)
      return
    }else{
      getPokemon((parseInt(pokemon.id) + 1))
    }
  }


  return (
    <div className={styles.pokedex}>
      <div className={styles.header}>
        <img className={styles.imageHeader} src={headerImg} alt="" />
      </div>

      <div className={styles.pokemonTitleBox}>
        <p className={styles.pokemonTitle}>{pokemon.name} <span>Nº0{parseInt(pokemon.id)  >= 100 ? parseInt(pokemon.id)  : (parseInt(pokemon.id)  > 10 ? `0${pokemon.id}` : `00${pokemon.id}`)}</span></p>
      </div>
      
      <div className={styles.displayInformation}>
        <img className={styles.button} id='left'  src={buttonLeft} alt="" onClick={pressLeft}/>
        <img className={styles.displayImg} src={displayImg} alt="" />
        <div className={styles.displayBox}>
          <img className={styles.pokemonImg} src={pokemon.pokemonURL} alt="" /> 
        </div>
        <img className={styles.button} id='right'  src={buttonRight} alt="" onClick={pressRight}/>
      </div>

      <div className={styles.informationBox}>
        <div className={styles.left}>
          <p className={styles.stats}>Type:</p>
          <div className='boxStyles'>
            {pokemon.type && pokemon.type.map((iten, index) => <span key={index} className={`${iten} card`}>{iten}</span>)}
          </div>
          
          <p className={styles.stats}>Weaknesses:</p>
          <div className='boxStyles'>
            {pokemon.weaknesses && pokemon.weaknesses.map((iten, index) => <span key={index} className={`${iten} card`}>{iten}</span>)}
          </div>
          
          <p className={styles.stats}>Stats:</p>
          <StatesBars hp={pokemon.hp} atk={pokemon.atk} def={pokemon.def} speed={pokemon.speed}/>

        </div>
        <div className={styles.right}>
          <p className={styles.statsRightBox}>Category:</p>
          <div className={styles.rightStatsBox}>
            {pokemon.category && pokemon.category.map((iten, index) => <span key={index} className='card'>{iten}</span>)}
          </div>
          <p className={styles.statsRightBox}>Abilities:</p>
          <div className={styles.rightStatsBox}>
            {pokemon.abilities && pokemon.abilities.map((iten, index) => <span key={indexedDB} className='card'>{iten}</span>)}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}
