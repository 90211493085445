import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBZYJS7KRoz02iKxTFRRrJmwbqJ_osAjEg",
    authDomain: "pokedex-f0e10.firebaseapp.com",
    projectId: "pokedex-f0e10",
    storageBucket: "pokedex-f0e10.appspot.com",
    messagingSenderId: "1015996671041",
    appId: "1:1015996671041:web:3d28f221bfcfa1ec27d2c0"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {db}